import React, {useContext, useEffect} from "react"
import Routes from "./routeur/Routes"
import {IntlContext} from "./context/Internationalization";


const App = () => {
    const {switchLanguage} = useContext(IntlContext)

    useEffect(() => {
        checkLanguageAndUpdateIfNeeded();
    })
    const handleLangUpdate = (lang) => {
        switchLanguage(lang)
    }
    const checkLanguageAndUpdateIfNeeded = () => {
        if (localStorage.getItem("forcedLang") === null || localStorage.getItem("forcedLang") === undefined) {
            handleLangUpdate(window.navigator.language.substr(0, 2));
        } else {
            handleLangUpdate(localStorage.getItem("forcedLang"))
        }
    }

    return (
        <Routes/>
    )
}
export default App;