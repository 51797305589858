import React from "react";
import {ReactComponent as Loader} from "../../assets/images/logo/log4w.svg";

export const SiteLoader=()=>{
    return(
        <div className="container-loader">
            <Loader/>
            <div><span>Chargement...</span></div>
        </div>
    )
}