import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import "animate.css/animate.min.css";
import {Auth0Provider} from "@auth0/auth0-react";
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import 'font-awesome/css/font-awesome.min.css';
import {IntlProviderWrapper} from "./context/Internationalization";
import "./assets/dashboarder/styles/card.css"
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



ReactDOM.render(
    <Auth0Provider
        useRefreshTokens={true}
        domain={process.env.REACT_APP_BASE_DOMAIN}
        clientId={process.env.REACT_APP_CLIENT_ID}
        redirectUri={process.env.REACT_APP_REDIRECT_URI}
        audience={process.env.REACT_APP_AUDIENCE}
        scope="openid profile email offline_access manage:account"
    >
        <IntlProviderWrapper>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
            <ToastContainer />
        </IntlProviderWrapper>
    </Auth0Provider>
    ,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
