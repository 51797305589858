import Siteroutes from "./siteroutes";
import DashboardRoutes from "./DashboardRoutes";

const Routes = [
    ...Siteroutes
]
const ProtectRoutes=[
    ...DashboardRoutes
]

export {Routes,ProtectRoutes}