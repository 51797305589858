import {lazy} from 'react'


const DashboardRoutes = [
    {

        component: lazy(() => import('../../pages/dashboarder/dashboarder/')),
        exact: true,
        ind:{
            index:true,
        }
    },
    {
        path:"/dashboard",
        component: lazy(() => import('../../pages/dashboarder/dashboarder/')),
        exact: true,
        ind:{}

    },
    {
        path:"/dashboard/chart",
        component: lazy(() => import('../../pages/dashboarder/dashboarder/')),
        exact: true,
        ind:{}

    },
    {
        path: '/dashboard/cards',
        component: lazy(() => import('../../pages/dashboarder/cards/')),
        exact: true,
        ind: {}
    },
    {
        path: '/dashboard/card/',
        component: lazy(() => import('../../pages/dashboarder/detailsCard/')),
        exact: true,
        ind: {}
    },
    {
        path: '/dashboard/profile',
        component: lazy(() => import('../../pages/dashboarder/profile/')),
        exact: true,
        ind: {}
    },
    {
        path: '/dashboard/developper',
        component: lazy(() => import('../../pages/dashboarder/developper/')),
        exact: true,
        ind: {}
    },
    {
        path: '/dashboard/entree',
        component: lazy(() => import('../../pages/dashboarder/inTrans/')),
        exact: true,
        ind: {}
    },
    {
        path: '/dashboard/sortie',
        component: lazy(() => import('../../pages/dashboarder/outTrans')),
        exact: true,
        ind: {}
    },

]

export default DashboardRoutes
