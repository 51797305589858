import React from "react";
import { Route, Routes as AppRoutes,Navigate } from 'react-router-dom'
import {ProtectRoutes,Routes as SiteRoutes} from "./routes/";
import {DashboarerLayout,SiteLayout} from "../layout";
import "../assets/style/loader.css"
import {useAuth0} from "@auth0/auth0-react";
import {E404} from "../pages/error";
import {SiteLoader} from "../components/elements/loaders";
import {Spinner} from "reactstrap";

const Routes=()=>{
    const {isLoading, isAuthenticated} = useAuth0();
    return (
        <AppRoutes>
            <Route path="/" element={<SiteLayout/>}>
             {
                 SiteRoutes.map((item,index)=>{
                        const Component=item.component
                        return(
                            <Route  path={item.path} index={item.index} {...item.ind}   element={
                                <React.Suspense fallback={<SiteLoader/>}>
                                    {<Component />}
                                </React.Suspense>
                            } key={index} />
                        )
                    })
                }
            </Route>
            {isAuthenticated &&
                <Route path="/dashboard" element={<DashboarerLayout/>}>
                    {
                        ProtectRoutes.map((item, index) => {
                            const Component = item.component
                            return (
                                <Route path={item.path} index={item.index} {...item.ind} element={
                                    <React.Suspense fallback={<div className="d-flex vh-100 col-12 align-items-center justify-content-center">
                                        <Spinner/>
                                    </div>}>
                                        {<Component/>}
                                    </React.Suspense>
                                } key={index}/>
                            )
                        })
                    }
                </Route>}
            {isLoading
                ?
                <Route path="*" element={<SiteLoader/>}/>
                :
                <React.Fragment>
                    <Route path="/dashboard/*" element={<Navigate to="/" />}/>
                    <Route path="*" element={<E404/>}/>
                </React.Fragment>
            }
        </AppRoutes>
    )
}
export default Routes;