
import {lazy} from 'react'

const Siteroutes = [
    {
        ind:{
            index:true,
        },
        component: lazy(() => import('../../pages/home/')),
        exact: true
    },
    {
        path: '/teams',
        component: lazy(() => import('../../pages/teams/')),
        exact: true,
        ind:{}
    },
    {
        path: '/contact',
        component: lazy(() => import('../../pages/contact/')),
        exact: true,
        ind:{}
    },

]

export default  Siteroutes;